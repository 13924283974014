import React, { useCallback, useState } from 'react'
import classNames from 'classnames'

import { EloButton } from '@elo-ui/components/elo-button'
import { ButtonVariantType } from '@elo-ui/components/elo-button/elo-button'

import './elo-info-message.scss'

export interface EloInfoMessageProps {
  status?: 'success' | 'warning' | 'error' | 'information'
  size?: 'small' | 'medium'
  className?: string
  children?: React.ReactNode
  title?: string
  icon?: React.ReactNode
  titlePosition?: 'start' | 'center'
  onSubmit?: (handleClose?: () => void) => void
  onDismiss?: (handleClose?: () => void) => void
  submitTitle?: string
  dismissTitle?: string
  dismissVariant?: ButtonVariantType
}

export const EloInfoMessage: React.FC<EloInfoMessageProps> = ({
  status = 'success',
  size = 'small',
  className,
  children,
  title,
  icon,
  titlePosition = 'start',
  onSubmit = null,
  onDismiss = null,
  submitTitle = '',
  dismissTitle = '',
  dismissVariant = 'ghost',
}) => {
  const classes = classNames(className, 'elo-info-message', `elo-info-message--${status}`, `elo-info-message--${size}`)
  const titleClasses = classNames('elo-info-message__title', `elo-info-message__title--${titlePosition}`, {
    'elo-info-message__title--action': onDismiss || onSubmit,
  })
  const isSmall = size === 'small'
  const [shouldRender, setShouldRender] = useState<boolean>(true)

  const handleClose = () => {
    setShouldRender(false)
  }

  const getButtons = useCallback(
    () => (
      <div className='elo-info-message__action-buttons'>
        {onSubmit && (
          <EloButton size='small' variant='secondary' onClick={() => onSubmit(handleClose)}>
            {submitTitle}
          </EloButton>
        )}
        {onDismiss && (
          <EloButton size='small' variant={dismissVariant} onClick={() => onDismiss(handleClose)}>
            {dismissTitle}
          </EloButton>
        )}
      </div>
    ),
    [onSubmit, onDismiss, submitTitle, dismissTitle]
  )

  return (
    <>
      {shouldRender && (
        <div className={classes}>
          <div className={titleClasses}>
            <div className='elo-info-message__title--title'>
              {icon}
              {isSmall ? children : title}
            </div>
            {(onSubmit || onDismiss) && getButtons()}
          </div>
          {!isSmall && <div className='elo-info-message__text'>{children}</div>}
        </div>
      )}
    </>
  )
}
