import React, { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

import { EloCloseIcon } from '../icons/regular'
import { EloButton } from '../elo-button'

import './elo-modal.scss'

interface Props {
  isOpen: boolean
  className?: string
  onClose?: (...args: any[]) => any
  showCloseButton?: boolean
  size?: 'small' | 'medium' | 'large' | 'x-large'
  isFullScreen?: boolean
  isOverlayFixed?: boolean
  children: React.ReactNode
}

export const EloModal: React.FC<Props> = ({
  className,
  isOpen = false,
  children,
  onClose,
  showCloseButton = true,
  size = 'small',
  isFullScreen = false,
  isOverlayFixed = false,
}) => {
  const handleEscape = useCallback(
    (e) => {
      if (e.key === 'Escape' && onClose && isOpen) {
        e.preventDefault()
        onClose(e)
      }
    },
    [onClose]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEscape)

    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [onClose])

  useEffect(() => {
    if (isFullScreen || isOverlayFixed) {
      if (isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'visible'
      }
    }

    return () => {
      if (isFullScreen || isOverlayFixed) {
        document.body.style.overflow = 'visible'
      }
    }
  }, [isOpen, isFullScreen, isOverlayFixed])

  const modalClasses = classNames(
    'elo-ui-modal',
    {
      [`elo-ui-modal--${size}`]: !isFullScreen,
      'elo-ui-modal--fullscreen': isFullScreen,
      'elo-ui-modal--with-fixed-overlay': isOverlayFixed,
    },
    className
  )

  return isOpen
    ? ReactDOM.createPortal(
        <>
          <div
            className={modalClasses}
            role='dialog'
            aria-modal='true'
            tabIndex={-1}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='elo-ui-modal__dialog'>
              <div className='elo-ui-modal__content'>
                {onClose && showCloseButton && (
                  <div className='elo-ui-modal__close-button'>
                    <EloButton onClick={onClose} leftIcon={<EloCloseIcon size={20} />} variant='ghost' />
                  </div>
                )}
                {children}
              </div>
            </div>
            {!isFullScreen && <div className='elo-ui-modal__overlay' />}
          </div>
        </>,
        document.body
      )
    : null
}
