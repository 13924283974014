import React from 'react'
import classNames from 'classnames'

import './elo-price-display.scss'

interface Props {
  size?: 'small' | 'medium' | 'large'
  type?: 'horizontal' | 'vertical'
  price: number | string
  oldPrice?: number | string
  supportiveText?: string
  netPrice?: string
  priceClassName?: string
}

export const EloPriceDisplay: React.FC<Props> = ({
  price,
  oldPrice,
  supportiveText,
  type = 'horizontal',
  size = 'medium',
  netPrice,
  priceClassName,
}) => {
  const className = classNames('elo_price_display', `elo_price_display--${size}`, `elo_price_display--${type}`)

  const NetPrice = () => (netPrice ? <span className='elo_price_display__supportive_text'>{netPrice}</span> : <></>)
  const SupportiveText = () =>
    supportiveText ? <span className='elo_price_display__supportive_text'>{supportiveText}</span> : <></>

  const priceClassNames = classNames('elo_price_display__price', priceClassName)

  return (
    <div className={className}>
      {type === 'vertical' && (
        <>
          <SupportiveText />
          <NetPrice />
        </>
      )}
      <span className='elo_price_display__pricing'>
        <span className={priceClassNames}>{price}</span>
        {oldPrice && <span className='elo_price_display__old_price'>{oldPrice}</span>}
      </span>

      {type === 'horizontal' && (
        <>
          <NetPrice />
          <SupportiveText />
        </>
      )}
    </div>
  )
}
